import React from "react";
import PlotlyPlot from 'react-plotly.js';
import useTheme from "@mui/material/styles/useTheme";
import darkTheme from '../theme.js';
import { merge } from 'lodash';

const CustomPlot = React.forwardRef((props, ref) => {
	const theme = useTheme(darkTheme);

	const { layout: layout_prop, data: data_prop, ...rest_props } = props;

	const layout = merge(layout_prop, {
		paper_bgcolor: "transparent",
		plot_bgcolor: "transparent",
		margin: {
			t: 10, b: 20,
			l: 60, r: 60,
		},
		xaxis: {
			color: theme.palette.text.primary,
			gridcolor: "rgba(255,255,255,0.2)",
		},
		yaxis: {
			color: theme.palette.text.primary,
			gridcolor: "rgba(255,255,255,0.2)",
		},
		legend: {
			font: {
				color: theme.palette.text.primary
			},
		},
		font: {
			color: theme.palette.text.secondary,
		},
		title: {
			font: {
				color: theme.palette.text.primary,
			}
		},
		scene: {
			xaxis: {
				color: theme.palette.text.primary
			},
			yaxis: {
				color: theme.palette.text.primary
			},
			zaxis: {
				color: theme.palette.text.primary
			},
		},
		polar: {
			bgcolor: "transparent",
			angularaxis: {
				gridcolor: "rgba(255,255,255,0.2)",
			},
			radialaxis: {
				gridcolor: "rgba(255,255,255,0.2)",
				linecolor: "rgba(255,255,255,0.8)",
				tickcolor: "rgba(255,255,255,0.8)",
				tickfont: {
					color: "rgba(255,255,255,0.8)",
				},
			},
		}
	});

	const data = data_prop.map(plotData => merge({
		colorbar: {
			tickfont: {
				color: theme.palette.text.primary
			}
		}
	}, plotData));

	const customProps = { layout, data, ...rest_props };
	return <PlotlyPlot ref={ref} {...customProps} />;
});

export default CustomPlot;