import React from 'react';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CustomPlot from '../components/CustomPlot';
import { useMeteo } from '../providers/meteo';

const unpack = (rows, key) => rows.map((row) => row[key]);

export default function CardVentilation(){
    const { loadingPbhl, ventilation } = useMeteo();
	const [chartData, setChartData] = React.useState();

	React.useEffect(() => {
        setChartData();
        if(ventilation.length > 0){
			let trace = {
				type: "scatter",
				mode: "lines",
				name: 'Indice de Ventilación',
				x: unpack(ventilation, 'date'),
				y: unpack(ventilation, 'value'),
			};
			setChartData({
				data: [trace],
				layout: { autosize: true },
			});
        }
    }, [ventilation]);

    return (
        <Card>
            <CardHeader
                titleTypographyProps={{ variant: "h6" }}
                title="Indice de Ventilación"
            />
            {loadingPbhl ? (
                <CardContent>
                    Cargando...
                </CardContent>
            ) : (
                (!chartData) ? (
                    <CardContent>
                        <Alert severity="info">No hay datos</Alert>
                    </CardContent>
                ): (
                    <CardContent sx={{ pt: 0, pl: 0, pr: 0 }}>
                        <CustomPlot
                            style={{ width: '100%', height: '100%' }}
                            useResizeHandler
                            data={chartData.data}
                            layout={chartData.layout}
                        />
                    </CardContent>
                )
            )}
        </Card>
    )
}