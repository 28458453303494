import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useMeteo } from '../providers/meteo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';
import './CardWindImages.scss';

//const unpack = (rows, key) => rows.map((row) => row[key]);

const valueLabelFormat = (value) => {
    let date = moment().startOf('day').hours(value);
    return date.format('HH:mm');
};

function BasicMenu({ onChange }) {
    const selectOption = (event) => {
        if(onChange) onChange(event.target.value);
    };
  
    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                    labelId="select-img-type"
                    id="select-img-type"
                    onChange={selectOption}
                    defaultValue="speed"
                >
                    <MenuItem value="speed">Velocidad</MenuItem>
                    <MenuItem value="vent">Ventilaci&oacute;n</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

export default function CardWindImages() {
    const { loadingImages, images } = useMeteo();
    const [current, setCurrent] = React.useState(0);
    const [total, setTotal] = React.useState(24);
    const [type, setType] = React.useState('speed');

    const [imgLoading, setImgLoading] = React.useState(true);

    React.useEffect(() => {
        setCurrent(0);
        if(loadingImages){
            setTotal(24);
        }
        else{
            setTotal(images.length);
        }
    }, [loadingImages, images.length]);

    const handleSliderChange = (event) => {
        setImgLoading(true);
        setCurrent(event.target.value);
    };

    const prevFrame = () => {
        if (current > 0){
            setImgLoading(true);
            setCurrent(current - 1);
        }
    };

    const nextFrame = () => {
        if (current < total - 1){
            setImgLoading(true);
            setCurrent(current + 1);
        }
    };

    const handleImageLoad = () => {
        setImgLoading(false);
    };

    const changeType = (val) => {
        setImgLoading(true);
        setType(val);
    };

    return (
        <Card>
            <CardHeader
                titleTypographyProps={{ variant: "h6" }}
                title={(type === 'speed') ? "Velocidad del Viento": 'Indice de Ventilación'}
                action={<BasicMenu onChange={changeType} />}
            />
            {(loadingImages) ? (
                <CardContent sx={{ aspectRatio: '16 / 9', backgroundColor: 'black' }}>Cargando...</CardContent>
            ) : (
                <CardContent sx={{ p: 0, position: 'relative', aspectRatio: '16 / 9', backgroundColor: 'black' }}>
                    {imgLoading && (
                        <div className="timelapse-loading">
                            <div>Cargando...</div>
                        </div>
                    )}
                    <img
                        className="timelapse-image"
                        onLoad={handleImageLoad}
                        src={images[current][type]}
                        draggable={false}
                        alt="imagen"
                    />
                </CardContent>
            )}
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <IconButton aria-label="anterior" onClick={prevFrame} disabled={loadingImages}>
                    <ArrowBackIcon />
                </IconButton>
                <Slider
                    sx={{ mx: 1 }}
                    size="small"
                    marks
                    value={current}
                    min={0}
                    step={1}
                    max={total - 1}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={valueLabelFormat}
                    aria-label="Small"
                    disabled={loadingImages}
                />
                <IconButton aria-label="siguiente" onClick={nextFrame} disabled={loadingImages}>
                    <ArrowForwardIcon />
                </IconButton>
            </CardActions>
        </Card>
    )
};