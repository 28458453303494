import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Box from "@mui/material/Box";
import AppBar from './AppBar';
import Main from '../views/Main';
import darkTheme from '../theme.js';
import { MeteoProvider } from '../providers/meteo';

function App() {
	return (
		<ThemeProvider theme={darkTheme}>
			<MeteoProvider>
				<AppBar />
				<Box sx={{ px: 0, py: 1, }}>
					<Main />
				</Box>
			</MeteoProvider>
		</ThemeProvider>
	);
}

export default App;
