import React from 'react';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CustomPlot from '../components/CustomPlot';
import { useMeteo } from '../providers/meteo';
import { blue } from '@mui/material/colors';

const dataWindRoseChart = {
	data: [],
	layout: {
		autosize: true,
	}
};



function getCardinalDirection(dir){
    if(dir <= 22.5 || dir > 337.5) return 'North';
    else if(dir > 22.5 && dir <= 67.5) return 'N-E';
    else if(dir > 67.5 && dir <= 112.5) return 'East';
    else if(dir > 112.5 && dir <= 157.5) return 'S-E';
    else if(dir > 157.5 && dir <= 202.5) return 'South';
    else if(dir > 202.5 && dir <= 247.5) return 'S-W';
    else if(dir > 247.5 && dir <= 292.5) return 'West';
    else if(dir > 292.5 && dir <= 337.5) return 'N-W';
}

function getSpeedGroup(spd){
    if(spd < 5) return 'g1';
    else if(spd < 8) return 'g2';
    else if(spd < 11) return 'g3';
    else return 'g4';
}

function getTotal(frequencies, group){
    let total = 0;
    for(let dir in frequencies){
        total += frequencies[dir][group];
    }
    return total;
}

function groupBy(frequencies, group){
    let result = [
        frequencies['North'][group],
        frequencies['N-E'][group],
        frequencies['East'][group],
        frequencies['S-E'][group],
        frequencies['South'][group],
        frequencies['S-W'][group],
        frequencies['West'][group],
        frequencies['N-W'][group],
    ];
    return result;
}

export default function CardWindRose(){
    const { loading, serie } = useMeteo();
	const [windRoseData, setWindRoseData] = React.useState(dataWindRoseChart);

	React.useEffect(() => {
        if(serie.length > 0){
            const frequencies = {
                "North": { g1: 0, g2: 0, g3: 0, g4: 0, },
                "N-E": { g1: 0, g2: 0, g3: 0, g4: 0, },
                "East": { g1: 0, g2: 0, g3: 0, g4: 0, },
                "S-E": { g1: 0, g2: 0, g3: 0, g4: 0, },
                "South": { g1: 0, g2: 0, g3: 0, g4: 0, },
                "S-W": { g1: 0, g2: 0, g3: 0, g4: 0, },
                "West": { g1: 0, g2: 0, g3: 0, g4: 0, },
                "N-W": { g1: 0, g2: 0, g3: 0, g4: 0, },
            };

            for(let ins of serie){
                let dir = getCardinalDirection(ins.direction);
                let grp = getSpeedGroup(ins.speed);
                frequencies[dir][grp] += 1;
            }

            let g1Total = getTotal(frequencies, 'g1');
            let g2Total = getTotal(frequencies, 'g2');
            let g3Total = getTotal(frequencies, 'g3');
            let g4Total = getTotal(frequencies, 'g4');

            for(let dir in frequencies){
                if(g1Total > 0) frequencies[dir]['g1'] = (frequencies[dir]['g1'] / g1Total) * 100;
                if(g2Total > 0) frequencies[dir]['g2'] = (frequencies[dir]['g2'] / g2Total) * 100;
                if(g3Total > 0) frequencies[dir]['g3'] = (frequencies[dir]['g3'] / g3Total) * 100;
                if(g4Total > 0) frequencies[dir]['g4'] = (frequencies[dir]['g4'] / g4Total) * 100;
            }

            setWindRoseData({
                data: [{
                    r: groupBy(frequencies, 'g4'),
                    theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
                    name: "11-14 m/s",
                    marker: {color: blue[800]},
                    type: "barpolar"
                }, {
                    r: groupBy(frequencies, 'g3'),
                    theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
                    name: "8-11 m/s",
                    marker: {color: blue[600]},
                    type: "barpolar"
                }, {
                    r: groupBy(frequencies, 'g2'),
                    theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
                    name: "5-8 m/s",
                    marker: {color: blue[400]},
                    type: "barpolar"
                }, {
                    r: groupBy(frequencies, 'g1'),
                    theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
                    name: "< 5 m/s",
                    marker: {color: blue[200]},
                    type: "barpolar"
                }],
                layout: {
                    autosize: true,
                    font: {size: 16},
                    legend: {font: {size: 16}},
                    polar: {
                        barmode: "overlay",
                        bargap: 0,
                        radialaxis: {
                            ticksuffix: "%",
                            angle: 45,
                            dtick: 20,
                        },
                        angularaxis: {direction: "clockwise"}
                    }
                }
            });
        }
    }, [serie]);

    return (
        <Card>
            <CardHeader
                titleTypographyProps={{ variant: "h6" }}
                title="Rosa de Viento"
            />
            {loading ? (
                <CardContent>
                    Cargando...
                </CardContent>
            ) : (
                (serie.length === 0) ? (
                    <CardContent>
                        <Alert severity="info">No hay datos</Alert>
                    </CardContent>
                ): (
                    <CardContent sx={{ pt: 0, pl: 0, pr: 0 }}>
                        <CustomPlot
                            style={{ width: '100%', height: '100%' }}
                            useResizeHandler
                            data={windRoseData.data}
                            layout={windRoseData.layout}
                        />
                    </CardContent>
                )
            )}
        </Card>
    )
}