import React, { useState } from 'react';
import { fetchMeteo } from '../utils/meteo-fetch';
import moment from 'moment';

export const MeteoContext = React.createContext();

export const useMeteo = () => React.useContext(MeteoContext);

const places = [
	{ label: 'Estacion Huasco', value: 'huasco' },
	{ label: 'Estacion MLC', value: 'mlc' },
];

const days = [
	{ label: 'Ayer', value: 'yesterday' },
	{ label: 'Hoy', value: 'today' },
	{ label: 'Mañana', value: 'tomorrow' },
];

export function MeteoProvider({ children }){
    const [loading, setLoading] = useState(true);
    const [windDirection, setWindDirection] = useState();
    const [windSpeed, setWindSpeed] = useState();
    const [currentPlace, setPlace] = useState(places[0].value);
	const [currentDay, setDay] = useState(days[1].value);
    const [serie, setSerie] = useState([]);

    const [loadingImages, setLoadingImages] = useState(true);
    const [imagesWind, setImagesWind] = useState();
    const [imagesVent, setImagesVent] = useState();
    const [images, setImages] = useState([]);

    const [loadingPbhl, setLoadingPbhl] = useState(true);
    const [pbhl, setPbhl] = useState([]);
    const [ventilation, setVentilation] = useState([]);

	React.useEffect(() => {
        setLoading(true);
        setLoadingImages(true);
        setLoadingPbhl(true);

        const startDate = moment().startOf('day');
        const endDate = moment().add(1, 'days').startOf('day');

        const placeIdWind = (currentPlace == 'huasco') ? '2': '12';
        fetchMeteo(startDate, endDate, 'hdwind_value_ts_wot_wsp', placeIdWind).then(json => {
            const filter = json.map(entry => ({
                date: entry.date_forecast,
                speed: entry.data_value,
            }));
            setWindSpeed(filter);
        });

        fetchMeteo(startDate, endDate, 'hdwind_value_ts_wot_wdi', placeIdWind).then(json => {
            const filter = json.map(entry => ({
                date: entry.date_forecast,
                direction: entry.data_value,
            }));
            setWindDirection(filter);
        });

        const placeIdImage = (currentPlace === 'huasco')? '1': '13';
        fetchMeteo(startDate, endDate, 'hdwind_file_graph_img_wsp', placeIdImage).then(json => {
            const filter = json.map(entry => ({
                date: entry.date_forecast,
                image: entry.data_file,
            }));
            setImagesWind(filter);
        });

        fetchMeteo(startDate, endDate, 'hdwind_file_graph_img_vidx', placeIdImage).then(json => {
            const filter = json.map(entry => ({
                date: entry.date_forecast,
                image: entry.data_file,
            }));
            setImagesVent(filter);
        });

        const placeIdPbhl = (currentPlace === 'huasco')? '7': '25';
        fetchMeteo(startDate, endDate, 'wrf_value_ts_md_pblh', placeIdPbhl).then(json => {
            const filter = json.map(entry => ({
                date: entry.date_forecast,
                value: entry.data_value,
            }));
            filter.sort((a, b) => a.date - b.date);
            setPbhl(filter);
        });
    }, [currentPlace, currentDay]);

	React.useEffect(() => {
        if(windDirection && windSpeed && windDirection.length > 0 && windSpeed.length > 0){
            const serie = [];
            for(let data of windDirection){
                let speedData = windSpeed.find(d => d.date === data.date);
                serie.push({
                    date: new Date(data.date),
                    direction: data.direction,
                    speed: (speedData)? speedData.speed: null,
                });
            }
            serie.sort((a, b) => a.date - b.date);
            setSerie(serie);
            setLoading(false);
        }
    }, [windDirection, windSpeed]);

	React.useEffect(() => {
        if(windSpeed && pbhl && windSpeed.length > 0 && pbhl.length > 0){
            const vent = [];
            for(let data of windSpeed){
                let pbhlData = pbhl.find(d => d.date === data.date);
                vent.push({
                    date: new Date(data.date),
                    value: data.speed * pbhlData.value,
                });
            }
            vent.sort((a, b) => a.date - b.date);
            setVentilation(vent);
            setLoadingPbhl(false);
        }
    }, [windSpeed, pbhl]);

	React.useEffect(() => {
        if(imagesVent && imagesWind && imagesVent.length > 0 && imagesWind.length > 0){
            const images = [];
            for(let data of imagesWind){
                let otherImage = imagesVent.find(d => d.date === data.date);
                images.push({
                    date: new Date(data.date),
                    speed: data.image,
                    vent: (otherImage)? otherImage.image: null,
                });
            }
            images.sort((a, b) => a.date - b.date);
            setImages(images);
            setLoadingImages(false);
        }
    }, [imagesVent, imagesWind]);

    const value = {
        currentPlace,
        places,
        setPlace,
        currentDay,
        days,
        setDay,
        loadingImages,
        loadingPbhl,
        loading,
        serie,
        images,
        pbhl,
        ventilation,
    };

    return (
        <MeteoContext.Provider value={value}>
            {children}
        </MeteoContext.Provider>
    )
}
