import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useMeteo } from '../providers/meteo';
import CardWindRose from '../components/CardWindRose';
import CardWindSpeed from '../components/CardWindSpeed';
import CardWindImages from '../components/CardWindImages';
import CardPbhl from '../components/CardPbhl';
import CardVentilation from '../components/CardVentilation';

export default function Main(){
    const { places, days, currentPlace, currentDay, setPlace, setDay } = useMeteo();

	const handlePlaceChange = (event) => setPlace(event.target.value);
	const handleDayChange = (event) => setDay(event.target.value);

	return (
		<Box sx={{
			my: 1,
			mx: { xs: 2, lg: 'auto' },
			width: { lg: 1200 }
		}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
						<InputLabel id="select-place">Lugar</InputLabel>
						<Select
							labelId="select-place"
							id="select-place"
							label="Lugar"
							onChange={handlePlaceChange}
							defaultValue={currentPlace}
						>
							{places.map((place) => (
								<MenuItem key={place.value} value={place.value}>{place.label}</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
						<InputLabel id="select-day">D&iacute;a</InputLabel>
						<Select
							labelId="select-day"
							id="select-day"
							label="Día"
							onChange={handleDayChange}
							defaultValue={currentDay}
							disabled
						>
							{days.map((day) => (
								<MenuItem key={day.value} value={day.value}>{day.label}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={5}>
					<CardWindRose />
				</Grid>
				<Grid item xs={12} md={7}>
					<CardWindImages />
				</Grid>
				<Grid item xs={12} md={6}>
					<CardWindSpeed />
				</Grid>
				<Grid item xs={12} md={6}>
					<CardPbhl />
				</Grid>
				<Grid item xs={12}>
					<CardVentilation />
				</Grid>
				{/* <Grid item xs={12} lg={6}>
					<Card>
						<CardHeader
							titleTypographyProps={{ variant: "h6" }}
							title="Chart 2"
						/>
						<CardContent sx={{ pt: 0, pl: 0, pr: 0 }}>
							<CustomPlot
								style={{ width: '100%', height: '100%' }}
								useResizeHandler
								data={windData2.data}
								layout={windData2.layout}
							/>
						</CardContent>
					</Card>
				</Grid> */}
			</Grid>
		</Box>
	)
}