const defaultHeaders = {
    'User-Agent': 'Mozilla/5.0',
    'Authorization': 'Api-Key XArHcVeK.sU2pbBxeRmK9lnGKvoINd2t6yQwJfc73',
    'Accept': 'application/json',
};

export async function fetchMeteo(start_date, end_date, tag_name, tu_id, get_only_ts=false, filtered=false){
    const startDate = start_date.format('YYYY-MM-DD+HH:mm:ss');
    const endDate = end_date.format('YYYY-MM-DD+HH:mm:ss');
    const url = `https://staging.msc.matcom.cl/api/meteo/artifact/data/display/?date_forecast__gte=${startDate}&date_forecast__lte=${endDate}&tag__tag_name=${tag_name}&tu__id=${tu_id}`;

    const res = await fetch(url, { headers: defaultHeaders });
    if(res.status !== 200) throw Error(`Error en la consulta de TU, con codigo ${res.status_code}`);

    const json = res.json();
    return json;
};